<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="fulfiller-order"
        :canDelete="false"
        :canEdit="false"
        :changeToggle="changeToggle"
        :loadingMsg="loadingMsg"
        navigation="fulfiller-orders"
        :onPullSuccessAsync="orderPulled"
        title="Order To Fulfill"
        :defaultBladeWidth="500">
        <template v-slot="{ item }">
            <v-list-item v-if="item != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(item.buyerID)" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>Customer</v-list-item-subtitle>
                    <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row dense>
                        <BT-Menu
                            hideHeader
                            icon="mdi-progress-question"
                            title="Status">
                            <template v-slot>
                                <v-list-item>
                                    <v-list-item-avatar><v-icon class="success--text">mdi-progress-check</v-icon></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Created</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar><v-icon :class="item.confirmedOn != null ? 'success--text' : null">mdi-progress-check</v-icon></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Confirmed</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.confirmedOn | toLongDateAndTime }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar><v-icon :class="item.isDispatched != null ? 'success--text' : null">mdi-progress-check</v-icon></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Processed</v-list-item-title>
                                        <v-list-item-subtitle>Done</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Menu>
                        <BT-Btn
                            v-if="item.fulfillerID == item.sellerID"
                            bladeName="customer"
                            navigation="customers"
                            icon="mdi-cog"
                            :id="item.supplyAgreementID"
                            small
                            title="View Customer Settings" />
                    </v-row>
                </v-list-item-action>
            </v-list-item>
           
            <BT-Field-String
                v-model="item.customerOrderNumber"
                label="Customer Order #"
                horizontal />

            <BT-Field-String
                v-model="item.purchaseOrderNumber"
                label="Purchase Order #"
                horizontal />

            <BT-Field-Date
                v-model="item.dueDate"
                label="Due On" />

            <v-list-item dense>
                <v-list-item-content>
                    <v-list-item-subtitle>Destination</v-list-item-subtitle>
                    <v-list-item-title>{{ item.location | toFamiliarLocationLine }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-slide-y-transition>
                <div v-if="showMore">
                    <BT-Field-Checkbox
                        v-model="item.isBuyerCourier"
                        key="33.1"
                        label="Customer Prefers To Organize Courier" />

                    <BT-Field-Checkbox
                        v-model="item.isRequestPickup"
                        key="33.2"
                        label="Customer Will Pickup" />

                    <BT-Field-Select
                        v-model="item.requestedDepartureLocationID"
                        key="33.3"
                        label="Supply From"
                        itemValue="id"
                        itemText="locationName"
                        :isEditing="!item.isDispatched"
                        navigation="locations" />

                </div>
            </v-slide-y-transition>

            <v-btn block text @click="showMore = !showMore">
                {{ showMore ? 'Hide Options' : 'More Options' }}
            </v-btn>

            <v-divider class="my-1" />

            <BT-Btn-Row>
                <template v-slot:right>
                    <BT-Btn
                        v-if="item.isDispatched"
                        bladeName="stock-consignment"
                        icon="mdi-clipboard-outline"
                        :id="item.id"
                        label="Consignment"
                        nav />

                    <BT-Btn
                        v-else
                        @click="processPurchaseOrder(item)"
                        icon="mdi-arrow-right-circle"
                        label="Consign"
                        title="Proceed to next step of purchase order - a stock consignment" />

                </template>
            </BT-Btn-Row>
            
            <BT-Table
                :canExportCSV="false"
                :canRefresh="false"
                :canSelect="false"
                :headers="[
                    { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                    { text: 'Description', value: 'description', display: true, subtitle: 1 },
                    { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency' },
                    { text: 'Total', value: 'total', display: true }]"
                hideActions
                :items="item.orderItems">
                <template v-slot:description="{ item }">
                    <v-edit-dialog>
                        <v-icon v-if="item.placeholderProductID != null" left small>mdi-alert-circle-outline</v-icon>{{ item.description }}
                        <template v-slot:input>
                            <BT-Field-Select
                                v-model="item.placeholderProductID"
                                navigation="public-products"
                                itemText="productName"
                                label="Billable Product"
                                single />
                        </template>
                    </v-edit-dialog>
                    
                </template>
                <template v-slot:total="{ item }">
                    <span align="right">
                    {{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                </template>
            </BT-Table>

            <h4 class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</h4>
            <h4 class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</h4>
            
            <v-divider />

            <v-row class="ma-0 pa-0 mx-3">
                <BT-Print
                    v-if="item != null && item.fulfillerID == item.sellerID"
                    buttonClass=""
                    :getFileName="item => `3PL Order #${item.customerOrderNumber}.pdf`"
                    :item="item"
                    :itemID="item.id"
                    :onPullSuccessAsync="pullForInvoice">
                    <template v-slot="invD">
                        <v-container v-if="invD != null" class="pdf-page">
                            <v-row>
                                <v-col cols="8">
                                    <div style="height: 25px;" />
                                    <v-img v-if="invD.strategy != 'jspdf'" class="pdf-logo" :src="invD.item.logoImgData" max-width="100px" max-height="100px" />
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-icon left small>mdi-account</v-icon><strong>{{ $BlitzIt.auth.session.company.accountName }}</strong>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null" >
                                                <v-icon left small>mdi-email</v-icon>{{ $BlitzIt.auth.session.company.primaryEmail }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                                <v-icon left small>mdi-phone</v-icon>{{ $BlitzIt.auth.session.company.phoneNumber }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="mt-5">
                                        <v-list-item-content>
                                            <v-list-item-subtitle>
                                                <v-icon left small>mdi-arrow-right-bottom</v-icon>BILLED TO
                                            </v-list-item-subtitle>
                                            <v-list-item-title>
                                                <v-icon left small>mdi-account</v-icon><strong>{{ item.buyer.companyName }}</strong>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-icon left small>mdi-map-marker</v-icon>{{ item.location | toLocationLineOne }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <v-icon left small>mdi-map-marker</v-icon>{{ item.location | toLocationLineTwo }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h2>Purchase Order</h2>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                            <v-list-item-title>{{ invD.item.customerOrderNumber }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                            <v-list-item-title>{{ invD.item.purchaseOrderNumber }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                            <v-list-item-title>{{ invD.item.issuedOn | toShortDate }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                            <v-list-item-title>{{ invD.item.dueOn | toShortDate }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                            <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                <v-col cols="2">QTY</v-col>
                                <v-col cols="6">DESCRIPTION</v-col>
                                <v-col cols="2">UNIT PRICE</v-col>
                                <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                            </v-row>

                            <v-row no-gutters v-for="(lineItem, index) in invD.item.lineItems" :key="index" style="font-size: 13px;">
                                <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                <v-col cols="6">{{ lineItem.description }}</v-col>
                                <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                            </v-row>
                            
                            <v-divider style="margin: 10px 0px;" />

                            <v-row dense style="font-size: 14px;">
                                <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                <v-col cols="3" style="text-align: right;">{{ invD.item.subTotal | toCurrency }}</v-col>
                            </v-row>
                            <v-row dense style="font-size: 14px;">
                                <v-col cols="9" style="text-align: right;">GST:</v-col>
                                <v-col cols="3" style="text-align: right;">{{ invD.item.taxTotal | toCurrency }}</v-col>
                            </v-row>
                            
                            <v-divider style="margin: 10px 0px;" />
                            
                            <v-row>
                                <v-col cols="9" style="text-align: right;">Total:</v-col>
                                <v-col cols="3" style="text-align: right;">
                                    <h4>{{ invD.item.amountTotal | toCurrency }}</h4>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </BT-Print>
                
                <v-spacer />

                <h3 class="text-right mx-3 mt-2">Total: {{ item.amountTotal | toCurrency }}</h3>

                <BT-Snack v-model="msg" />
            </v-row>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { getImageData } from '~helpers';

export default {
    name: 'Customer-Order-Blade',
    components: {
        // BTOrderItemsTableDialog: () => import('~components/BT-Order-Items-Table-Dialog.vue'),
        // BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        // BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            changeToggle: false,
            loadingMsg: null,
            mAgreementID: null,
            msg: null,
            showMore: false,
            showToggle: false
        }
    },
    props: {
        bladesData: null
    },
    mounted() {
        if (this.$route.params.id == 'new') {
            //is new
            if (this.$route.query.agreementID != null) {
                this.mAgreementID = this.$route.query.agreementID;
            }
            else {
                this.showToggle = !this.showToggle;
                this.mAgreementID = '1';
            }
        }
        else {
            this.mAgreementID = '1';
        }
    },
    methods: {
        async orderPulled(order) {
            if (order != null && this.isLengthyArray(order.orderItems)) {
                order.orderItems = order.orderItems.orderBy('sortNumber');
            }

            return order;
        },
        async processPurchaseOrder(po) {
            this.loadingMsg = 'Consigning';

            var data = { id: po.id, isProcessed: true, rowVersion: po.rowVersion }
            try {
                await this.$BlitzIt.api.patch('fulfiller-orders', data);
                po.isDispatched = true;
                this.changeToggle = !this.changeToggle;
            } 
            finally {
                this.loadingMsg = null;
            }
        },
        async pullForInvoice(item, id) {
            var uri = this.companyLogoURL(item.sellerID);
            var logoImgData = await getImageData(uri, false);

            var dropoff = {
                performedOn: item.dueDate,
                orderItems: item.orderItems,
                id: id
            };
            
            var res = await this.$BlitzIt.api.post('customer-orders', dropoff, null, '/ProposedInvoice/ProposedInvoice');

            var e = {
                ...res.data.data,
                logoImgData: logoImgData
            };
            
            return e;
        }
     }
}
</script>